angular.module('fingerink')
    .factory('userService', ['$http', 'session', function ($http, session) {
        var sdo = {


            getUsuarios: function (params) {
                return $http({
                    method: 'GET',
                    url: 'users' + ((params != null) ? '?' + decodeURIComponent($.param(params)) : ""),
                    crossDomain: true
                });
            },

            createUser: function (user) {
                user.password = btoa(user.password);
                return $http({
                    method: 'POST',
                    url: 'users',
                    crossDomain: true,
                    data: user
                });
            },

            updateUsuario: function (user) {
                return $http({
                    method: 'PUT',
                    url: 'users',
                    crossDomain: true,
                    data: user
                });
            },

            
            updateUserPassword: function (user) {
                return $http({
                    method: 'PUT',
                    url: 'users/userPassword',
                    crossDomain: true,
                    data: user
                });
            },

            

            deleteUser: function (user) {
                return $http({
                    method: 'DELETE',
                    url: 'users/' + user.id,
                    crossDomain: true,
                    data: user
                });
            },



            changePassword: function (old, newP) {
                return $http({
                    method: 'PUT',
                    url: 'users/password?oldPassword=' + btoa(old) + '&newPassword=' + btoa(newP),
                    crossDomain: true
                });
            },
            
            updateFirstTime: function (user) {
                return $http({
                    method: 'PUT',
                    url: 'users/updateFirstTime',
                    crossDomain: true
                });
            },

            singImageURL: function () {
                return $http({
                    method: 'GET',
                    url: 'users/singImageURL',
                    crossDomain: true
                });
            },

            uploadImageURLBase64: function (base64) {
                return $http({
                    method: 'POST',
                    url: 'users/singImageURL/base64',
                    crossDomain: true,
                    data: base64
                });
            },
            uploadImageURLFile: function (file) {
                var fd = new FormData();
                fd.append('file', file);
                return $http.post('users/singImageURL/file', fd, {
                    withCredentials: false,
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined,
                        'Authorization': 'Bearer ' + session.get().token
                    },
                    crossDomain: true
                });
            }





        };
        return sdo;
}]);
